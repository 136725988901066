.login-right-wrap.admin h1{
    margin: 0;
    padding-bottom: 20px;
}
.register-btn{
    margin-top: 45px;
}
.form-control.employees{
    height: 43px;
}
.freetrail{
    .input-block{
        margin-bottom: 20px;
    }
}