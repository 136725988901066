$__primarycolor: #7539FF;
$__primarycolorhover: #6b36de;
$__primarylight: rgba(255, 255, 255, 0.2);
$__primary__light__blue: #ECEBFF;
$__primarydark: #4A3AFF;
$__secondarycolor: #28084B;
$__white: #fff;
$__cylindrical: #F7F8F9;
$__grayish__blue: #3F4254;
$__gray: #333;
$__gray3: #3F4254;
$__title__black:#1F0066;
$__gray__dark: #878A99;
$__blue__light: #D1BBFF;
$__gray__lightdark: #6c757d;
$__sandstone: #9e9e9e;
$__goose-gray: rgba(38, 17, 85, 0.05);
$__light__gooses: #F0F1F5;
$__light__vampire: #dbdfea;
$__light__vampires: rgb(117 117 117);
$__grey: #D9D9D9;
$__note__light: #989c9e;
$__grey__light: 0px 16px 32px rgba(65, 152, 255, 0.2);
$__alice__blue: #EFF2F5;
$__light__smokey-grays: #292D32;
$__gainsboro__color: #DCDCDC;
$__light__grayish: #e7eaf3;
$__white__smoke: #EFEFEF;
$__dopely-__colors: #E5E7EB;
$__graybackblue: #1B2559;
$__light__whites: #E5E5E5;
$__light__slate__grey: rgb(82 63 105 / 5%);
$__medium__slate__grey: rgba(190, 190, 190, 0.2);
$__cloud__grays: #E0E0E0;
$__ghost__white: #F3F3F9;
$__smoke__white: #F3F3F3;
$__light__battle__garys: #F2ECFF;
$__alice__blue__color: rgba(231, 234, 252, 0.75);
$__gray15: #878787;
$__gray13: rgba(33, 33, 33, 0.05);
$__mainly_blue: rgb(31 45 61 / 8%);
$__mainly_blue-dark: #eff2f7;
$__golden__yellow: #ffd200;
$__bg__info__light: #DDF3FF;
$__bg__primary__light: #EADDFF;
$__bg__warning__light: #FFF6DC;
$__bg__blue__light: #E9F2FF;
$__bg__green__light: #E1FFED;
$__bg__danger__light: #FFECEC;
$__gray__light: rgb(190 190 190 / 20%);
$__wild_sand: #E6E9E8;
$__success__light: #33B469;
$__dark: #000000;
$__text__danger__light: #ED3A3A;
$__bg__info__blue: #E9F2FF;
$__text-warning__light: #EBBC2E;
$__mine__shaft: #282828;
$__light__vampires:#EFF0F7;
$__light__vampire: #B5B5C3;
$__dark__gooses: #101924;
$__light__goosesvalue: #F7F8F9;
$__light-goose-grays:#E9ECEF;
$__light-goose:#E8EBED;
$__violet:#3E45EB;
$__violets:#1B2850;
$__violet__dark:#377dff;
$__violet__light:#5156be;
$__black:#000;
$__light__ash__gray: #8a8a8a;
$__dark__ash__gray: #555;
$__dark__ash__grays:#212B36;
$__goose-grays: #B8BCC9;
$__light__smokey-gray:#5E5873;
$__goose-greys:#565656;
$__sky__blue: #2DA7FF; 
$__success__lights: #0F9D58;
$__purple__light: #c580ff;
$__successcolor: #28C76F;
$__dangercolor: #f62d51;
$__dangerred: #ff0000;
$__warningcolor: #FF9900;
$__yellowcolor: #ffb800;
$__warningcolors: #FC8543;
$__frog__blue: #e6e6e6;
$__frog__blues: #e6e6e6;
$__fire__red: #fd625e;
$__grey__color:#8d8d8d;
$__gray__cloud: #999;
$__dark__gray__cloud: #9f9f9f;
$__cloud__gray: #eeeeee;
$__light__white: #fafafa;
$__dark__light: #f5f5f5;
$__dark__lights: #D8D8D8;
$__sandstones: #C4C4C4;
$__dark__blues: #32577D;
$__dark__violets: #092C4C;
$__dark__violets__light: #092C4C;
$__dark__gray: #888888;
$__dark__grays: #BABFC7;
$__orangecolor: #FE9F43;
$__mute_color:#6B7A99;
$__black_color:#141432;
$__blue_black:#353570;
$__ash:#dee2e6;
$__light__grey:#8ea0af;
$__dark__grey:#1d1d42;
$__light_white_gray:#F7F7FF;
$__light_ash_gray:#F6F2FF;
$__dark__black:#1F2937;
$__light__gray: #BDBDBD;
$__light__gray__has: #E3E3E3;
$__light__gray__dark: #757575;
$__light__gray__light: #95979b;
$__gray__light__white: #f6f7f9;
$__dark__black_light: rgba(118, 56, 255, 0.05);
$__darktableheadbg : #0C0C20;
$__tablehead__bg: #7638FF;
$__black__nuetral: #2c3038;
$__grey__nuetral: #95979b;
$__invborder__color: #F2F2F2;
$__bodybg__color: #f2f2f2;
$__exchangebg: #F4F4F4;
$__tablelightbg: #FCFCFC;
$__exchangeborder: #D9D9D9;
$__downloadcolor: #7638FF;
$__printcolor: #4361EE;
$__invseven_border: #95979B;
$__invninebg_color: #fafafa;
$__darkthemebg : #252526;
$__darktheme__header : rgba(250, 250, 250, 0.1);
$__darktheme__logobg :  rgba(255, 255, 255, 0.1);
$__darktheme__tablebg : rgba(242, 242, 242, 0.1);
$__blackcolor:#212529;
$__primarycolor1:#2C3038;
$__info__color:  #EFE9FF;
$__dark__brown:  #AE2C2C;
$__gray2:  #4F4F4F;
$__success__green: #19A855;
$__light__ash: #BEBEBE;
$__gray__white__light:#808080;
$__dark__border: #DDDEE1;
$__light_black:#3F3F3F;
$__gray_gray:#E0E0E0;
$__sky_lightblue:#54C1FF;
$__shade_cyan:#2596be;
$__light_orange:#FFE0BB;

$__heading__color:#0F0033;
$__text__success__dark: #1D8147;

$__info__payment:#2465BC;
$__info__companies:#1F0066;
$__primary__companies:#808080;
$__history__light:#FFEFDD;
$__history__orange:#EC8000;
$__pending_light:#C99700;
$__box__shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
$__dull__ash: #EFF0F6;
$__filter__box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
$__table__head__bg:  linear-gradient(320deg, #DDCEFF 0%, #DBECFF 100%);

$__whitecolor:#fff;
$__orangecolor:#FDA600;
$__deep__skyblue: #42c0fb;
$__blue: #15558d;
$__grayblack: #34444c;
$__dark__maroon: #ff737b;
$__darkpurple: #1ec1b0;
$__dark__blues: #00c5fb;
$__default__black: #263238;
$__ash__grays: #bbc4cc;
$__grayblacks: #16191c;
$__light__black: #2e3840;
$__metallic-silver: #e3e3e3;
$__dark__mode__bg: #1C1D22;
$__dark__mode__body: #2A2B2F;
$__dark__mode__color: #AAAAAA;
$__dark__mode__table__header: #3B3C3E;
$__dark__mode__table__border: #1E1F25;
$__dark__box__shadow: rgba(193, 193, 193, 0.07);
$__filter__btns__shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
$__filter__btns__bg: #36373D;
$__success__rgba__bg: rgba(40, 178, 77, 0.10);
$__danger__rgba__bg: rgba(230, 33, 33, 0.10);
$__blue__rgba__bg: rgba(60, 144, 209, 0.10);
$__orange__rgba__bg: rgba(237, 153, 29, 0.10);
$__table__btns__bg: #37383D;
$__input__dull__bg: rgba(193, 193, 193, 0.07);
$__light__gray__bg: rgba(243,243,243, 0.10);
$__dark__cancel__color: #FF1717;
$__ribbon__secondary__bg: rgba(247,49,100, 0.3);
$__ribbon__success__bg: rgba(81,187,37, 0.3);
$__ribbon__primary__bg: rgba(115,102,255, 0.3);
$__primayrgcolor__rgba__bg: rgba(117,57,255, .3);
$__warningcolor__rgba__bg: rgba(255,193,7, .3);
$__lightcolor__rgba__bg: rgba(166,166,166, .3);
$__primayrgcolor__rgba__bg1: rgba(117,57,255, .7);
$__bg__info__light__rgba: rgba(221,243,255, .12);
$__bg__green__light__rgba: rgba(225,255,237, .12);
$__invoice__terms__bg__rgba: rgba(239,239,239, .12);
$__success__toggle__rgba: rgba(85,206,99, .3);
$__white__rgba: rgba(255,255,255, .3);
$__subscribe__bg__rgba: rgba(237,58,58, .3);
$__subscribe__color: rgba(237,58,58, .9);
$__payment__bg__rgba: rgba(233,242,255, .3);
$__payment__color: rgba(36,101,188, .9);
$__payment__color__bg: rgba(36,101,188, .3);
$__invoice__bg: rgba(225,255,237, .3);



