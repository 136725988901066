/****** UTILS ******/
@import "utils/variables";
@import "utils/mixins";

/******* BASE *******/
@import "base/reset";
@import "base/typography";
@import "base/colors"; 
@import "base/base";
@import "base/grid";

/******* COMPONENTS ******/
@import "components/button";


/****** LAYOUT ******/
@import "layout/avatar";
@import "layout/boostrap";
@import "layout/breadcrumb";
@import "layout/calendar";
@import "layout/chart";
@import "layout/chat";
@import "layout/components";
@import "layout/datatable";
@import "layout/footer";
@import "layout/header";
@import "layout/loader";
@import "layout/select";
@import "layout/sidebar";
@import "layout/nav-tab";
@import "layout/notification";
@import "layout/popup";
@import "layout/table";
@import "layout/index";
@import "layout/theme-settings";

/****** PAGES ******/
@import "pages/add-invoice";
@import "pages/add-customer";
@import "pages/price-plan";
@import "pages/ticket-kanban";
@import "pages/alertify";
@import "pages/blog";
@import "pages/chat";
@import "pages/common";
@import "pages/filter-menu";
@import "pages/dashboard";
@import "pages/file-upload";
@import "pages/invoice";
@import "pages/email";
@import "pages/ribbon";
@import "pages/timeline";
@import "pages/login";
@import "pages/settings";
@import "pages/layout-settings";
@import "pages/form-wizard";
@import "pages/invoice/bus-ticket.scss";
@import "pages/invoice/car-booking-invoice.scss";
@import "pages/invoice/cashreceipt-1.scss";
@import "pages/invoice/cashreceipt-2.scss";
@import "pages/invoice/cashreceipt-3.scss";
@import "pages/invoice/cashreceipt-4.scss";
@import "pages/invoice/coffee-shop.scss";
@import "pages/invoice/domain-hosting.scss";
@import "pages/invoice/ecommerce.scss";
@import "pages/invoice/fitness-center.scss";
@import "pages/invoice/flight-booking-invoice.scss";
@import "pages/invoice/hotel-booking.scss";
@import "pages/invoice/internet-billing.scss";
@import "pages/invoice/medical.scss";
@import "pages/invoice/moneyexchange.scss";
@import "pages/invoice/moneyexchange.scss";
@import "pages/invoice/movie-ticket-booking.scss";
@import "pages/invoice/student-billing.scss";
@import "pages/invoice/train-ticket-booking.scss";
@import "pages/invoice/general-invoice-1.scss";
@import "pages/invoice/general-invoice-2.scss";
@import "pages/invoice/general-invoice-3.scss";
@import "pages/invoice/general-invoice-4.scss";
@import "pages/invoice/general-invoice-5.scss";
@import "pages/invoice/invoice-templates.scss";
@import "pages/invoice/invoice-two.scss";
@import "pages/signature.scss";
@import "pages/signature-invoice.scss";
@import "pages/notifications.scss";
@import "pages/invoice/invoice-one.scss";
@import "pages/invoice/invoice-two.scss";
@import "pages/invoice/invoice-three.scss";
@import "pages/invoice/invoice-four.scss";
@import "pages/invoice/invoice-five.scss";
@import "pages/invoice/template-invoice.scss";
@import "pages/companies.scss";
@import "pages/inventory-history.scss";
@import "pages/domain-request";
@import "pages/customer-ledger.scss";
@import "pages/purchase-transaction.scss";
@import "pages/subscription.scss";
@import "pages/packages";
@import "pages/two-factor.scss";
@import "pages/preference-setting.scss";
@import "pages/plan-billing";
@import "pages/purchase-report";
@import "pages/tax-purchase";
@import "pages/profit-loss-data";
@import "pages/ledger";
@import "pages/delivery-challans";
@import "pages/invoice/invoice-subscription";
@import "pages/super-admin-dashboard";

@import "pages/email-restpassword.scss";
@import "pages/custom-filed.scss";
@import "pages/sales-return-report.scss";
@import "pages/tax-report-salestax.scss";
@import "pages/profit-loss-list.scss";
@import "pages/admin-register.scss";
@import "pages/saas-login.scss";



