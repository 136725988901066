.loader-overlay {
    position: fixed; /* Fixed position to cover the entire viewport */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1000; /* Ensure it appears on top of other elements */
    pointer-events: none; /* Allow clicks to pass through the overlay */
    opacity: 0.5;
}

.loader-overlay .spinner {
    pointer-events: auto; /* Allow interaction with the spinner if necessary */
}
.loader-image {
    width: 50px; /* Set width of your loader image */
    height: auto; /* Maintain aspect ratio */
}
